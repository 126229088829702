/* src/components/ControlPanel/ControlPanel.css */

.control-panel-main {
    flex: 1; /* Toma el espacio restante */
    height: auto; /* Asegura que el contenido ocupe todo el espacio del viewport */
    box-sizing: border-box; /* Incluye el padding dentro del tamaño del contenido */
  }
  
  .control-panel-box {
    height: auto;
    margin-top: 10px;
    background: #fff;  /* Color de fondo debe ser blanco pero se puso negro para pruebas*/
    border-radius: 20px;
    box-shadow: 0 20px 35px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* Evita que el contenido se desborde */
    margin-left: 200px;
  }
  
  .options {
    display: flex;
    list-style-type: none;
    padding: 10px;
    margin: 0;
  }
  
  .options li {
    flex: 1; /* Cada pestaña ocupa igual espacio */
    text-align: center;
    padding: 15px 0;
    cursor: pointer;
    background: #eee;
    text-transform: capitalize;
    border-radius: 10px;
    margin: 0 5px; /* Espacio entre pestañas */
    transition: background 0.3s, color 0.3s;
  }
  
  .options li:first-child {
    margin-left: 0;
  }
  
  .options li:last-child {
    margin-right: 0;
  }
  
  .options li:hover {
    background: #CC0000;
    color: #fff;
  }
  
  .options li.active {
    background: #CC0000;
    color: #fff;
  }
  
  .course {
    display: flex;
    flex-direction: column; /* Apilar widgets verticalmente */
    margin-top: 10px;
    padding:10px;
  }
  
  .course .box {
    width: 100%; /* Ocupa todo el ancho disponible */
    box-shadow: none; /* Opcional: eliminar sombra si no es necesaria */
  }
  
  @media (max-width: 767px) {
    .control-panel-main {
    padding: 0; 
    margin: 0; 
    width: 100%;
  }
  
    .control-panel-box {
    width: 100%;
    height: auto;
    padding: 0;
    margin-top: 0;
    background: #fff;  /* Color de fondo debe ser blanco pero se puso negro para pruebas*/
    border-radius: 0;
    box-shadow: 0 20px 35px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* Evita que el contenido se desborde */
    margin-left: 0;
  }
  
  .options {
    margin-bottom: 0;
    padding: 20px;
  }
  
  .course {
    margin-top: 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  }