/* Añadimos el estilo para el menú desplegable */
.sidebar {
  width: 200px;
  height: 100vh;
  background-color: #fff;
  position: fixed; /* Para que se mantenga en su lugar */
  top: 0;
  left: 0;
  z-index: 100; /* Coloca el sidebar encima del contenido */
  box-shadow: 2px 0 15px rgba(0, 0, 0, 0.2); /* Sombra más amplia y envolvente */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}


/* Estilo del logo en la parte superior */
.sidebar-logo img {
  width: 100px; /* Ajusta el tamaño del logo */
  margin-bottom: 20px;
}

.sidebar-username{
  font-weight: bold;
  font-size: 18px;
  color: #000;
  margin-bottom: 20px;
}

/* Estilo del menú */
.sidebar-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items:start;
}

.menu-items {
  display: flex;
  align-items: center;
  width: 80%;
  padding: 10px 20px;
  font-size: 14px;
  color: #000000;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Roboto', sans-serif;
  font-style: none;
  text-decoration: none;
}

.menu-items i {
  margin-right: 10px;
}

.menu-items:hover {
  background-color: #ededed; /* Color al hacer hover */
}

.menu-items.active {
  background-color: #ededed; /* Color que indica que la página está activa */
}
