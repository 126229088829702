
  .add-button {
    margin-left: auto;
  }
  
  .add-button button {
    margin-left: auto;
    padding: 8px 12px;
    background-color:#292828;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .add-button button:hover {
    background-color: #CC0000;
  }
  
  .widget-usuarios {
    max-height: calc(100vh - 150px);
  }
  
  .usuarios-table-container {
    overflow-y: auto;
    max-height: calc(100vh - 200px);
    text-align: center;
    border-radius: 10px;
    clip-path: inset(0 0 0 0 round 10px); /* Asegura los bordes redondeados */
  }
  
  /* Estilo del contenedor del scroll */
  .usuarios-table-container::-webkit-scrollbar {
    width: 8px; /* Ancho de la barra de desplazamiento */
  }
  
  /* Estilo del "track" de la barra de desplazamiento (fondo) */
  .usuarios-table-container::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color del track */
    border-radius: 10px; /* Bordes redondeados del track */
  }
  
  /* Estilo del "thumb" (el bloque que se mueve dentro de la barra de desplazamiento) */
  .usuarios-table-container::-webkit-scrollbar-thumb {
    background-color: #888; /* Color del thumb */
    border-radius: 10px; /* Bordes redondeados del thumb */
    border: 2px solid #f1f1f1; /* Espacio entre el thumb y el track */
  }
  
  /* Cambiar el color del thumb cuando se pasa el mouse sobre la barra */
  .usuarios-table-container::-webkit-scrollbar-thumb:hover {
    background-color:#CC0000; /* Color al pasar el mouse */
  }
  
  .usuarios-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
  }
  
  .th {
    position: sticky; /* Encabezado fijo */
    top: 0;
    border-bottom: 2px solid #ddd;
    padding: 10px 12px;
    background-color: #CC0000;
    color: #fff;
    text-align: center;
    font-size: 16px;
    z-index: 1; /* Encabezado sobre las filas */
  }
  
  .td {
    border-bottom: 1px solid #eee;
    padding: 10px 12px;
    font-size: 14px;
    vertical-align: middle;
    color: black; /* Texto negro */
    text-align: center;
    transition: background-color 0.3s ease;
  }

  .td-buttons{
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 10px 12px;
    font-size: 14px;
    vertical-align: middle;
  }

  .td-buttons select{
    width: fit-content;
    padding: 8px 12px;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    outline: none;
    cursor: pointer;
    text-align: center;
    background-color: transparent;
  }
  
  .table-row {
    transition: background-color 0.3s ease;
  }
  
  .table-row:nth-child(odd) {
    background-color: #f9f9f9; /* Color claro para las filas impares */
  }
  
  .table-row:nth-child(even) {
    background-color: #eee; /* Color un poco más oscuro para las filas pares */
  }
  
  .table-row:hover {
    background-color: #d7d7d7; /* Fondo ligeramente más claro al hacer hover */
  }
  
  .actions-td {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .action-button {
    padding: 6px 10px;
    background-color: #CC0000;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
  }
  
  .action-button:hover {
    background-color: #CC0000; /* Cambia el color al hacer hover */
  }
  
  .edit-button {
    background-color: #292828;
  }
  
  .update-button {
    background-color: transparent; /* Fondo transparente */
    border: none; /* Sin borde */
    cursor: pointer; /* Cursor de puntero */
    padding: 0; /* Sin relleno */
}

.update-button i {
    color: #000000; /* Color del icono */
    font-size: 20px; /* Tamaño del icono */
    transition: color 0.3s ease; /* Transición en el color */
    padding-top: 3px;
}

.update-button:hover i {
    color: #ff0000; /* Cambia de color al pasar el cursor */
}

.update-button p{
  font-size: 10px;
}
  
  .icon {
    margin-right: 6px;
    color: #fff;
  }
  
  .sort-button {
    background-color: var(--secondary);
    color: var(--white);
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
    transition: background-color 0.3s ease;
  }
  
  /* Estilo general para el contenedor de filtros */
  .usuarios-filters-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background-color: var(--background);
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap; /* Para que los filtros se acomoden mejor en pantallas pequeñas */
  }
  
  /* Estilo para cada filtro individual */
  .usuarios-filter {
    flex: 1;
    margin: 0 10px;
    position: relative; /* Necesario para los botones de limpiar */
  }
  
  .usuarios-filter label {
    font-size: 14px;
    color: var(--link-gray);
    margin-bottom: 5px;
    display: block;
  }

  .usuarios-filter select {
    width: 100%;
    padding: 8px 12px;
    border-radius: 4px;
    border: none;
    background-color: var(--white);
    color: var(--link-gray);
    font-size: 14px;
    transition: border-color 0.2s ease-in-out;
    outline: none;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* Sombra en la derecha y abajo */
  }
  
  .usuarios-filter select:focus {
    border-color: blue 3s;
    outline: none;
  }
  
  .usuarios-filter select:hover {
    border-color: blue;
  }
  
  /* Estilo para el input de fecha */
  .usuarios-filter-input {
    padding: 10px;
    border: 1px solid var(--gray-border);
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    max-width: 200px;
    background-color: var(--background);
    color: var(--primary);
    transition: border-color 0.3s ease;
  }
  
  .usuarios-filter-input:focus {
    border-color: var(--primary);
    outline: none;
  }
  
  .usuarios-filter-input::-webkit-calendar-picker-indicator {
    filter: invert(50%);
  }
  
  /* Botón para limpiar filtros individualmente */
  .usuarios-clear-filter-button {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    color: var(--link-gray);
    padding: 0;
    transition: color 0.2s ease;
  }
  
  .clear-filter-button:hover {
    background-color: var(--primary);
    color: var(--white);
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Estilo para el botón de aplicar filtros */
  .apply-filters-button {
    background-color: var(--primary);
    color: var(--white);
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .apply-filters-button:hover {
    background-color: var(--primary-hover);
  }
  
  /* Estilo para el botón de limpiar todos los filtros */
  .clear-filters-button {
    background-color: var(--secondary);
    color: var(--white);
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
    transition: background-color 0.3s ease;
  }
  
  .clear-filters-button:hover {
    background-color: var(--secondary-hover);
  }
  
  /* Adaptación para pantallas pequeñas */
  @media (max-width: 768px) {
    .filters-container {
        flex-direction: column;
        align-items: flex-start;
    }
  
    .filter {
        width: 100%;
        margin: 10px 0;
    }
  
    .clear-filters-button {
        width: 100%; /* Hacer que el botón de limpiar filtros ocupe todo el ancho */
        margin: 10px 0;
    }
    
    .widget-maquinas {
      max-height: calc(100vh - 150px);
    }
    
    .table-container {
      overflow-y: auto;
      max-height: calc(100vh - 350px);
      text-align: center;
      border-radius: 10px;
      clip-path: inset(0 0 0 0 round 10px); /* Asegura los bordes redondeados */
    }
  
    .table {
      width: 100%;
    }
  }
  