/* src/styles/Welcome.css */

.welcome-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Ocupa toda la pantalla */
    background-color: #ffffff; /* Cambia el color según tu diseño */
}

.welcome-logo-container {
    width: 150px;
    height: 150px;
}

.welcome-logo {
    width: 100%;
    height: 100%;
}

.welcome-message-title {
    font-size: 16px;
    font-weight:normal;
    color: #cc0000;
    white-space: pre; /* Para mantener espacios en blanco si es necesario */
    animation: fadeIn 1s; /* Ejemplo de animación */
}

.welcome-message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin:30px;
}

.welcome-message{
    font-size: 30px;
    font-weight: normal;
    color: #000000;
    white-space: pre; /* Para mantener espacios en blanco si es necesario */
    animation: fadeIn 1s; /* Ejemplo de animación */
}

.welcome-username {
    font-size: 30px;
    font-weight: bold;
    color: #000000;
    white-space: pre; /* Para mantener espacios en blanco si es necesario */
    animation: fadeIn 1s; /* Ejemplo de animación */
}

.welcome-animation {
    margin-top: 20px;
    font-size: 1.2rem;
    color: #666;
    animation: fadeIn 1s; /* Ejemplo de animación */
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
