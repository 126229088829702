.background-maintenance {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.content-container {
  flex-grow: 1; /* Permite que el contenido principal ocupe el espacio restante */
  padding: 20px;
  overflow-y: auto; /* Permite scroll si el contenido es muy largo */
}

.container-plants {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.maintenance-container {
  flex-grow: 1;
  padding: 20px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.maintenance-container h3 {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  margin-left: 200px;
}

/* Contenedor de los botones de mantenimiento */
.maintenance-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr)); /* Hasta 3 botones por fila, adaptativos */
  grid-auto-rows: 1fr; /* Las filas se ajustan para ocupar el mismo espacio proporcional */
  gap: 40px;
  width: calc(80% - 200px);
  padding: 30px;
  box-sizing: border-box;
  height: 80%;
  overflow: hidden; /* Evita el desbordamiento */
  border: none;
  margin-left: 200px;
}

/* Botones de mantenimiento */
.button-mantenimiento {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 12px;
  cursor: pointer;
  transition: 0.15s;
  -webkit-transition: 0.15s; /* Prefijo para transiciones en Safari */
  font-weight: bolder;
  text-decoration: none;
  text-align: center;
  width: auto;
  height: 100%; /* Ajustado a una altura fija para consistencia */
  background-size: cover;
  background-position: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Agrega sombra para mejor visualización */
  border: none;
}

/* Contenido del botón */
.button-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  border-radius: 1px;
}

/* Imagen dentro del botón */
.button-image {
  height: 60%; /* Ajusta la altura de la imagen */
  width: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 1px;
  object-fit: cover;
  object-position: center;
}

/* Texto dentro del botón */
.button-text {
  font-size: 18px;
  margin-top: 10px; /* Espacio entre la imagen y el texto */
  color: #000000;
}

/* Estilo del botón al pasar el cursor */
.button-mantenimiento:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  transform: scale(1.05);
}

/* Estilo del icono dentro del botón */
.button-mantenimiento i {
  font-size: 20px;
  color: rgb(0, 0, 0);
}

.button-mantenimiento:hover i {
  color: rgb(0, 0, 0);
}

/* Media Queries para tablets */
@media (max-width: 1024px) {
  .maintenance-container {
    padding-top: 40px;
  }

  .maintenance-container h3 {
    font-size: 1.8rem;
    margin-bottom: 15px;
    margin-left: 0;
  }

  .maintenance-items {
    grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
    gap: 20px;
    width: 100%;
    margin-left: 0;
  }

  .button-mantenimiento {
    padding: 10px;
  }

  .button-image {
    height: 50px;
    width: 50px;
  }

  .button-text {
    font-size: 16px;
  }
}

/* Media Queries para móviles */
@media (max-width: 768px) {
  .maintenance-container {
    padding-top: 30px;
  }

  .maintenance-container h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    margin-left: 0;
  }

  .maintenance-items {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    gap: 15px;
    width: 100%;
    margin-left: 0;
  }

  .button-mantenimiento {
    padding: 0;
  }

  .button-image {
    height: 15px;
    width: 15px;
  }

  .button-text {
    font-size: 14px;
  }
  .button-content {
    display: flex;
    flex-direction: row; /* Cambiado a row para alinear imagen y texto horizontalmente */
    align-items: center; /* Alinea verticalmente en el centro */
    justify-content: flex-start; /* Alinea el contenido al inicio */
    height: 100%;
    width: 100%;
    background-color: #ffffff;
    border-radius: 1px;
  }
  
  .button-image {
    height: 15px; /* Tamaño de imagen para móviles */
    width: 15px; /* Tamaño de imagen para móviles */
    background-size: cover;
    background-position: center;
    border-radius: 1px;
    object-fit: cover;
    object-position: center;
  }
  
}