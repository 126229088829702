/* login.css */
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;
}

.login-logo {
  width: 150px;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.login-title {
  font-size: 24px;
  font-weight: 500;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 10px;
  padding-bottom: 0;
}

.login-subtitle {
  font-size: 16px;
  margin-bottom: 30px;
}

.login-input {
  width: 300px;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.login-password-container {
  position: relative;
  width: 300px;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.login-password-input {
  padding-right: 0;
  width: calc(100% - 40px);
  height: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  background-color: transparent;

}

.login-toggle-password-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.login-button {
  width: 300px;
  padding: 10px;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #333;
}

.login-footer {
  font-size: 12px;
  color: #666;
  margin-top: 20px;
}

.login-link {
  color: #000;
  text-decoration: none;
  font-weight: bold;
}

.login-error-message {
  color: red;
  font-size: 14px;
}

.login-signup-text {
  font-size: 14px;
  color: #666;
  margin-top: 10px;
}

.login-signup-link {
  color: #000;
  font-weight: bold;
  cursor: pointer;
}

.remember-me-container {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.login-button-container {
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}

.login-google-container {
  width: auto;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

.login-divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.login-divider::before,
.login-divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #000000;
}

.login-divider::before {
  margin-right: 10px;
}

.login-divider::after {
  margin-left: 10px;
}

.login-divider span {
  font-size: 14px;
  color: #000000;
}
