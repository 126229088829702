/* src/MachineTypes.css */
.machine-types-page {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin-left: 200px;
}

.machine-types-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
  justify-content: center;
  height: 100vh;
}

.machine-types-container h3 {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0;
  text-align: center;
  font-weight: bold;
}

.machine-types-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  width: 80%;
  padding-top: 1rem;
  font-family: 'Roboto', sans-serif;
  overflow-y: auto;
  height: 100%;
}

.machine-type-item {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.machine-type-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.machine-types-list a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
  transition: color 0.3s, text-decoration 0.3s;
}

.machine-types-list a:hover {
  color: #0056b3;
  text-decoration: underline;
}

/* Media query para pantallas móviles */
@media (max-width: 768px) {
  .machine-types-page {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    margin-left: 0;
  }
  
  .machine-types-list {
    display: block; /* Mostrar en lista para pantallas pequeñas */
  }

  .machine-type-item {
    margin-bottom: 10px; /* Espaciado vertical entre los items */
  }
}
