/* Estilos para el perfil de usuario */

.main-container-login{
  display: flex;
  height: 100vh;
}
.container-login-settings {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-left: 250px;
    padding: 20px; 
    padding-right: 200px;
    width: 100%;
    background-color: #fff;
  }

  .container-user-info {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .title-login-settings {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
  }

  .profile-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
    width:fit-content;
  }
  
  .profile-image {
    font-size: 100px; /* Ajusta el tamaño del ícono */
    width: 100px;
    height: 100px;
    color: #b7b7b7; /* Cambia el color según sea necesario */
  }
  
  .label-profile-settings {
    font-size: 18px;
    font-weight: 500;
    color: #333;
    margin-bottom: 5px;
  }
  
  .label-username{
    font-size: 18px;
    font-weight: lighter;
    color: #333;
    margin:0;
    margin-bottom: 10px;

  }

  .label-email{
    font-size: 18px;
    font-weight: lighter;
    color: #333;
    margin:0;
    margin-bottom: 10px;
  }

  .input-login-settings {
    width: 300px;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
  
  .password-container-login-settings {
    position: relative;
    width: 300px;
  }
  
  .password-input-login-settings {
    padding-right: 40px;
  }
  
  .toggle-password-icon-login-settings {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  .button-login-settings {
    width: 300px;
    padding: 10px;
    background-color: #000;
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .button-login-settings:hover {
    background-color: #333;
  }
  
  .error-message-login-settings {
    color: red;
    font-size: 14px;
  }
  
  .edit-password-container {
    display: flex;
    margin-top: 10px;
    align-items: center;
    gap: 30px;
  }

  .edit-password-container span{
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }

  .edit-password-container span:hover{
    text-decoration: underline;
  }
  