/* Fondo y configuración general del contenedor principal de Home */
.page {
  position: relative;
  height: 100vh; /* Ocupa el 100% del viewport */
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto; /* Permite el scroll solo si es necesario en la parte inferior del contenedor */
  margin-left: 200px; /* Ajusta la posición del sidebar a la derecha */

}

.home {
  display: flex;
  flex-direction: column; /* Alinea los elementos en vertical */
  height: 100vh; /* Ocupa el 100% del viewport */
  overflow: hidden; /* Evita el scroll global en toda la página */
  background-color: white;
  padding-top: 80px;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 15px 20px;
}

header .logo {
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
}

header nav {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

header nav a {
  color: #333;
  text-decoration: none;
  font-size: 16px;
}

/* Estilo para los encabezados */
.h2-absolute, .h3-absolute {
  position: static; /* Fija los encabezados en su posición */
  color: rgb(0, 0, 0); /* Ajusta según sea necesario */
  padding: 10px;
  margin: 0;
  z-index: 500; /* Asegura que los encabezados estén por encima del contenido */
}

.h3-absolute {
  text-align: center;
  font-size: 35px;
  margin-top: 10px; /* Ajusta el margen superior */
}

.h2-absolute {
  text-align: center;
  margin-bottom: 10px; /* Ajusta el margen inferior */
}

.highlight {
  color: crimson;
}

.darpha {
  color: rgb(218, 185, 1);
}

/* Ajustes para pantallas pequeñas (móviles) */
@media (max-width: 767px) {
  /* Fondo y configuración general del contenedor principal de Home */
.page {
  position: relative;
  height: 100vh; /* Ocupa el 100% del viewport */
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto; /* Permite el scroll solo si es necesario en la parte inferior del contenedor */
  margin-left: 0;
}
  .home {
    padding-top: 60px; /* Ajusta para dar más espacio al header en móviles */
  }

  header {
    padding: 10px 15px;
  }

  header .logo {
    font-size: 18px;
  }

  header nav a {
    font-size: 14px;
  }

  .h2-absolute {
    font-size: 24px; /* Ajusta el tamaño de fuente si es necesario */
    margin-bottom: 15px; /* Reduce el margen inferior */
  }

  .h3-absolute {
    font-size: 28px; /* Ajusta el tamaño de fuente si es necesario */
    margin-top: 20px; /* Reduce el margen superior */
  }

  .center {
    gap: 10px; /* Reduce el espacio entre las cards */
    margin-top: 20px; /* Reduce el margen superior para las cards */
    align-items: center;
    flex-direction: column; /* Alinea las cards en una columna en pantallas pequeñas */
  }

  .article-card {
    width: 90vw; /* Escala a 90% del ancho del viewport */
    height: 250px; /* Ajusta la altura en pantallas pequeñas */
  }
}

/* Ajustes para pantallas medianas (tabletas) */
@media (min-width: 768px) and (max-width: 1024px) {
  .h2-absolute {
    font-size: 26px; /* Ajusta el tamaño de fuente si es necesario */
    margin-bottom: 20px; /* Ajusta el margen inferior */
  }

  .h3-absolute {
    font-size: 32px; /* Ajusta el tamaño de fuente si es necesario */
    margin-top: 30px; /* Ajusta el margen superior */
  }

  .center {
    gap: 15px; /* Espacio entre las cards */
    margin-top: 40px; /* Ajusta el margen superior para las cards */
    flex-direction: row; /* Alinea las cards en una fila en pantallas medianas */
    justify-content: center;
    flex-wrap: wrap; /* Permite que las cards se reorganicen en filas */
  }

  .article-card {
    width: 45vw; /* Escala dinámicamente según el ancho de la pantalla */
    height: 300px; /* Ajusta la altura en pantallas medianas */
  }
}

/* Ajustes para pantallas grandes (escritorios) */
@media (min-width: 1025px) {
  
  .h2-absolute {
    font-size: 30px; /* Ajusta el tamaño de fuente si es necesario */
    margin-bottom: 1px; /* Ajusta el margen inferior */
  }

  .h3-absolute {
    font-size: 35px; /* Ajusta el tamaño de fuente si es necesario */
    margin-top: 60px; /* Ajusta el margen superior */
  }

  .center {
    gap: 50px; /* Mayor espacio entre las cards */
    margin-top: 70px; /* Ajusta el margen superior para las cards */
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .article-card {
    width: 37vw; /* Escala a 37% del ancho del viewport */
    height: 45vh; /* Ajusta la altura */
  }

  .article-card .content {
    padding: 20px;
  }

  .article-card .title {
    font-size: 22px; /* Aumenta el tamaño de la fuente */
  }

  .article-card .date {
    font-size: 14px; /* Ajusta el tamaño de la fecha */
  }
}

/* Estilo para las cards */
.center {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
  flex-wrap: wrap;
}

.article-card {
  max-width: 100%;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
              0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 300ms;
  cursor: pointer;
}

.article-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
              0 10px 10px rgba(0, 0, 0, 0.22);
}

.article-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.article-card .content {
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  padding: 15px;
  height: auto;
  bottom: 0;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.6));
  display: flex;
  flex-direction: column;
  align-items: center; /* Alinea horizontalmente */
  justify-content: center; /* Alinea verticalmente */
  text-align: center; /* Centra el texto */
}

.article-card .date,
.article-card .title {
  margin: 0;
}

.article-card .date {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 3px;
}

.article-card .title {
  font-size: 17px;
  color: #fff;
}




