/* Desactivar scroll mientras el formulario está abierto */
body.form-active {
  overflow: hidden;
}

.info-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow: auto;
  background-color: #f5f5f5;
  background-size: cover;
  background-position: center;
  padding-top: 0;
  align-items: center;
}

.info-page h1, h2, h3 {
  color: #333;
  text-align: center;
  padding-bottom: 20px;
}

.info-page p {
  font-size: 1.1em;
  line-height: 1.6;
  color: #555;
  text-align: center;
}

.roles-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 0;
  padding: 20px;
}

.roles-section h2 {
  padding:0;
  margin:0;
}

.role {
  width: 100%; /* Cambiar a 100% para móviles */
  background-color: #ffffffe1; /* Fondo claro para las secciones de rol */
  padding: 10px;
  border-radius: 8px;
  box-shadow: 6px 6px 0 rgba(201, 201, 201, 0.252);
  display: flex;
  flex-direction: column; /* Asegúrate de que el contenido se apile verticalmente */
  border: 0.5px solid rgb(202, 202, 202);
  transition: transform 0.5s ease, border 0.5s ease; /* Transiciones suaves para transform y border */
}

.role:hover{
  transform: matrix3d(1.05, 0, 0, 0, 0, 1.05, 0, 0, 0, 0, 1.05, 0, 0, 0, 0, 1);
  transition: transform 0.5s ease;
}

.roles-footer {
  display: flex;
  justify-content: center; /* Centrar horizontalmente */
  margin-top: auto; /* Empujar el botón hacia abajo */
  padding: 20px; /* Añadir espaciado alrededor */
}


/* Estilos minimalistas y modernos para los elementos li */
.role ul {
  list-style: none; /* Eliminamos los puntos de la lista */
  padding: 0;
  margin: 0;
}

.role ul li {
  margin-bottom: 10px; /* Espacio entre los elementos */
  padding: 10px 15px; /* Espaciado interno */
  background-color: #fff; /* Fondo blanco */
  font-size: 1rem; /* Tamaño de fuente */
  color: #333; /* Color de texto */
}

.role ul li::before {
  content: '•'; /* Añadir un símbolo decorativo antes de cada elemento */
  color: #cc0000; /* Color del símbolo */
  font-size: 1.2rem;
  margin-right: 10px;
  vertical-align: middle;
}

.solicitar-acceso-button {
  background-color: #f7e40c9d; /* Color de fondo */
  color: #fff; /* Color del texto */
  border: none; /* Sin borde */
  border-radius: 5px; /* Bordes redondeados */
  padding: 10px 20px; /* Espaciado interno */
  font-size: 16px; /* Tamaño de la fuente */
  cursor: pointer; /* Cambia el cursor al pasar el mouse */
  display: flex; /* Usar flexbox para alinear el icono */
  align-items: center; /* Centrar el contenido verticalmente */
  transition: background-color 0.3s ease; /* Transición suave para el color de fondo */
  color: #333;
}

.solicitar-acceso-button:hover {
  background-color: #f7e40c;
  transform: matrix3d(1.05, 0, 0, 0, 0, 1.05, 0, 0, 0, 0, 1.05, 0, 0, 0, 0, 1);
  transition: transform 0.3s ease;
}

.solicitar-acceso-button i {
  margin-left: 8px; /* Espacio entre el texto y el icono */
}

.contact-link {
  display: block;
  font-size: 1.1em;
  color: #cc0000;
  text-decoration: none;
  text-align: center; /* Centrar el enlace */
}

.contact-link:hover {
  text-decoration: underline;
}

.image-section {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Fondo difuminado cuando el formulario está visible */
.page.form-active {
  position: relative;
}

.page.form-active:before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente */
  backdrop-filter: blur(5px); /* Efecto de difuminado */
  z-index: 1;
}

/* Estilo general para el formulario */
.form-container {
  position: fixed;
  top: 50%;
  left: 56%;
  transform: translate(-50%, -50%);
  background-color: #f9f9f9;
  z-index: 2; /* Asegura que el formulario esté sobre el fondo */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
}

/* Estilo para los títulos */
.form-container h2 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
  color: #333;
  padding-top: 10px;
}

/* Estilo para las etiquetas de los inputs */
.form-container label {
  font-size: 14px;
  color: var(--link-gray);
  margin-bottom: 8px;
  display: block;
}

/* Estilo para los inputs de texto */
.form-container input[type="text"] {
  width: 95%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: var(--white);
  color: var(--link-gray);
  font-size: 14px;
  transition: border-color 0.2s ease-in-out;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  outline: none;
}

.form-container input[type="text"]:focus {
  border-color: #007bff;
  outline: none;
}

/* Estilo para el select de planta */
.form-container select {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: var(--white);
  color: var(--link-gray);
  font-size: 14px;
  transition: border-color 0.2s ease-in-out;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  outline: none;
}

.form-container select:focus {
  border-color: #007bff;
  outline: none;
}

.submit-button {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  margin-right: 10px;
  margin-top: 20px;
}

.submit-button:hover {
  background-color: #2dd428a8;
}
.submit-button i{
  color: #f5f5f5;
  margin-left: 8px;
}

.cancel-button {
    position: absolute; /* Posiciona el botón de manera absoluta dentro del contenedor */
    top: 10px; /* Ajusta el botón 10px desde la parte superior */
    right: 15px; /* Ajusta el botón 10px desde la parte derecha */
    background-color: transparent;
    border: none;
    font-size: 12px;
    cursor: pointer;
    padding: 5px;
}

.cancel-button i{
  color: #000000;
}

/* Media Queries */
@media (min-width: 768px) {
  .role {
    width: 30%; /* Aumentar el ancho en pantallas más grandes */
  }
}

/* Ajustes para pantallas pequeñas (móviles) */
@media (max-width: 767px) {
  .info-container {
    padding: 10px; /* Menos padding en móviles */
  }

  h1 {
    font-size: 1.5em; /* Ajustar tamaño de texto */
  }

  h2 {
    font-size: 1.3em;
  }

  h3 {
    font-size: 1.1em;
  }

  .roles-section {
    flex-direction: column; /* Cambiar a columna para móviles */
  }

  .role {
    width: 100%; /* Mantener 100% en móviles */
  }

  .contact-link {
    font-size: 1em; /* Ajustar tamaño del enlace */
  }
}
