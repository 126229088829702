.bottom-nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    z-index: 100;
    border-radius: 20px 20px 0 0;
    height: 80px;
    padding-top: 10px;
    align-items: center;
  }
  
  .nav-item {
    text-align: center;
    color: black;
    flex-grow: 1;
  }
  
  .icon {
    font-size: 24px;
  }
  
  .icon.active {
    color: blue; /* El color activo para el icono seleccionado */
  }
  
  /* Solo se mostrará en pantallas móviles */
  @media (min-width: 768px) {
    .bottom-nav {
      display: none;
    }
  }
  