.tools-herramientas {
    display: flex; /* Activa Flexbox */
    justify-content: space-between; /* Distribuye los elementos con espacio entre ellos */
    gap: 20px; /* Espacio entre las secciones */
    margin-left: 220px;
    margin-top: 0px;
    height: 90%;
    margin-right: 20px;
}
.tools-section-herrameintasdisponibles,
.tools-section-herramientasprestadas {
    flex: 1; /* Ambos elementos ocupan el mismo ancho */
    padding: 20px;
    border: 1px solid #ccc; /* Agrega un borde para visualización */
    border-radius: 10px;
    background-color: #f9f9f9; /* Fondo claro */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Sombra para darle estilo */
    max-height: calc(100vh - 100px); /* Asegura que ocupen el espacio disponible vertical */
    overflow-y: auto; /* Permite scroll si el contenido sobrepasa */
    
}
  
  .tools-error {
    color: #dc3545;
    margin-bottom: 15px;
  }
  
  .add-button {
    margin-left: auto;
  }
  
  .add-button button {
    display: flex;
    gap: 10px;
    margin-left: auto;
    padding: 8px 12px;
    background-color:#292828;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .add-button button i {
    color: #fff;
    font-size: 16px;
  }
  
  .add-button button:hover {
    background-color: #CC0000;
  }
  
  .widget-herramientas {
    max-height: calc(100vh - 150px);
  }
  
  .table-container {
    overflow-y: auto;
    max-height: calc(100vh - 200px);
    text-align: center;
    border-radius: 10px;
    clip-path: inset(0 0 0 0 round 10px); /* Asegura los bordes redondeados */
  }
  
  /* Estilo del contenedor del scroll */
  .table-container::-webkit-scrollbar {
    width: 8px; /* Ancho de la barra de desplazamiento */
  }
  
  /* Estilo del "track" de la barra de desplazamiento (fondo) */
  .table-container::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color del track */
    border-radius: 10px; /* Bordes redondeados del track */
  }
  
  /* Estilo del "thumb" (el bloque que se mueve dentro de la barra de desplazamiento) */
  .table-container::-webkit-scrollbar-thumb {
    background-color: #888; /* Color del thumb */
    border-radius: 10px; /* Bordes redondeados del thumb */
    border: 2px solid #f1f1f1; /* Espacio entre el thumb y el track */
  }
  
  /* Cambiar el color del thumb cuando se pasa el mouse sobre la barra */
  .table-container::-webkit-scrollbar-thumb:hover {
    background-color:#CC0000; /* Color al pasar el mouse */
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
  }
  
  .th {
    position: sticky; /* Encabezado fijo */
    top: 0;
    border-bottom: 2px solid #ddd;
    padding: 10px 12px;
    background-color: #CC0000;
    color: #fff;
    text-align: center;
    font-size: 16px;
    z-index: 1; /* Encabezado sobre las filas */
  }
  
  .td {
    border-bottom: 1px solid #eee;
    padding: 10px 12px;
    font-size: 14px;
    vertical-align: middle;
    color: black; /* Texto negro */
    text-align: center;
    transition: background-color 0.3s ease;
  }

  .td-buttons{
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px 12px;
    font-size: 14px;
    vertical-align: middle;
  }
  
  .table-row {
    transition: background-color 0.3s ease;
  }
  
  .table-row:nth-child(odd) {
    background-color: #f9f9f9; /* Color claro para las filas impares */
  }
  
  .table-row:nth-child(even) {
    background-color: #eee; /* Color un poco más oscuro para las filas pares */
  }
  
  .table-row:hover {
    background-color: #d7d7d7; /* Fondo ligeramente más claro al hacer hover */
  }
  
  .actions-td {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .action-button {
    padding: 6px 10px;
    background-color: #CC0000;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
  }
  
  .action-button:hover {
    background-color: #CC0000; /* Cambia el color al hacer hover */
  }
  
  .edit-button {
    background-color: #292828;
  }
  
  .delete-button {
    background-color: transparent; /* Fondo transparente */
    border: none; /* Sin borde */
    cursor: pointer; /* Cursor de puntero */
    padding: 0; /* Sin relleno */
}

.delete-button i {
    color: #000000; /* Color del icono */
    font-size: 20px; /* Tamaño del icono */
    transition: color 0.3s ease; /* Transición en el color */
    padding-top: 3px;
}

.delete-button:hover i {
    color: #ff0000; /* Cambia de color al pasar el cursor */
}

.delete-button p{
  font-size: 10px;
}

.take-button {
  background-color: transparent; /* Fondo transparente */
  border: none; /* Sin borde */
  cursor: pointer; /* Cursor de puntero */
  padding: 0; /* Sin relleno */
}

.take-button i {
  color: #000000; /* Color del icono */
  font-size: 20px; /* Tamaño del icono */
  transition: color 0.3s ease; /* Transición en el color */
  padding-top: 3px;
}

.take-button:hover i {
  color: #FF0000; /* Cambia de color al pasar el cursor */
}

.take-button p{
font-size: 10px;
}

.return-button {
  background-color: transparent; /* Fondo transparente */
  border: none; /* Sin borde */
  cursor: pointer; /* Cursor de puntero */
  padding: 0; /* Sin relleno */
}

.return-button i {
  color: #000000; /* Color del icono */
  font-size: 20px; /* Tamaño del icono */
  transition: color 0.3s ease; /* Transición en el color */
  padding-top: 3px;
}

.return-button:hover i {
  color: #FF0000; /* Cambia de color al pasar el cursor */
}

.return-button p
{
font-size: 10px;
}


  
  .icon {
    margin-right: 6px;
    color: #fff;
  }
  
  .sort-button {
    background-color: var(--secondary);
    color: var(--white);
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
    transition: background-color 0.3s ease;
  }
  
  /* Estilo general para el contenedor de filtros */
  .widget-add-tool-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background-color: var(--background);
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap; /* Para que los filtros se acomoden mejor en pantallas pequeñas */
  }
  
  /* Estilo para cada filtro individual */
  .filter {
    flex: 1;
    min-width: 50px; /* Asegura que los campos no sean demasiado pequeños */
    margin: 0 0px;
    position: relative; /* Necesario para los botones de limpiar */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 100%; /* Asegura que los campos no se pasen del contenedor */
  }
  
  .filter label {
    font-size: 14px;
    color: var(--link-gray);
    margin-bottom: 5px;
    display: block;
  }
  
  /* Estilo para el select */
  .filter select {
    width: 100%;
    padding: 8px 8px;
    border-radius: 4px;
    border: none;
    background-color: var(--white);
    color: var(--link-gray);
    font-size: 14px;
    transition: border-color 0.2s ease-in-out;
    outline: none;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* Sombra en la derecha y abajo */
  }
  
  
  .filter select:focus {
    border-color: blue 3s;
    outline: none;
  }
  
  .filter select:hover {
    border-color: blue;
  }
  
  /* Estilo para el input de fecha */
  .filter input {
    width: 100%;
    padding: 8px 12px;
    border-radius: 4px;
    border: none;
    background-color: var(--white);
    color: var(--link-gray);
    font-size: 14px;
    transition: border-color 0.2s ease-in-out;
    outline: none;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;

  }
  
  
  .filter input:focus {
    border-color: blue 3s;
    outline: none;
  }
  
  .filter-input::-webkit-calendar-picker-indicator {
    filter: invert(50%);
  }
  
  /* Botón para limpiar filtros individualmente */
  .clear-filter-button {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    color: var(--link-gray);
    padding: 0;
    transition: color 0.2s ease;
  }
  
  .clear-filter-button:hover {
    background-color: var(--primary);
    color: var(--white);
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Estilo para el botón de aplicar filtros */
  .apply-filters-button {
    background-color: var(--primary);
    color: var(--white);
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .apply-filters-button:hover {
    background-color: var(--primary-hover);
  }
  
  /* Estilo para el botón de limpiar todos los filtros */
  .clear-filters-button {
    background-color: var(--secondary);
    color: var(--white);
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
    transition: background-color 0.3s ease;
  }
  
  .clear-filters-button:hover {
    background-color: var(--secondary-hover);
  }
  
  /* Adaptación para pantallas pequeñas */
  @media (max-width: 768px) {
    .filters-container {
        flex-direction: column;
        align-items: flex-start;
    }
  
    .filter {
        width: 100%;
        margin: 10px 0;
    }
  
    .clear-filters-button {
        width: 100%; /* Hacer que el botón de limpiar filtros ocupe todo el ancho */
        margin: 10px 0;
    }
    
    .widget-maquinas {
      max-height: calc(100vh - 150px);
    }
    
    .table-container {
      overflow-y: auto;
      max-height: calc(100vh - 350px);
      text-align: center;
      border-radius: 10px;
      clip-path: inset(0 0 0 0 round 10px); /* Asegura los bordes redondeados */
    }
  
    .table {
      width: 100%;
    }
    
  }
.title-tools{
  margin-left: 630px;
  margin-top: 50px;
}
.filter-container {
  display: flex;
  flex-wrap: wrap; /* Permite que los elementos se ajusten si no caben en una fila */
  gap: 10px; /* Espacio entre los elementos */
}